import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DonneesAccesSips} from 'src/app/models/donnees-acces-sips';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {PaymentService} from 'src/app/services/common/payment.service';
import {ContratService} from '@app/services/common/contrat.service';
import {TranslateService} from '@ngx-translate/core';
import {CommonService} from '@app/services/common/common.service';
import {AngularticsTagcoService} from '@app/services/common/angulartics-tagco.service';
import {
  KEY_DATA_LAYER,
  KEY_DATA_PAYMENT,
  KEY_PRODUCT_NAME,
  MODE_PAYMENT,
  PATH_BASE_PRODUCT_TRACKING_CONF,
  PAYMENT_KO,
  PAYMENT_OK,
  TYPE_CONTRAT_INFIRMIER,
  CLIENT_AUTHENTIFIE_OK
} from '@app/models/constants';
import {InfoPopIn, MtCaptchaService} from '@co/gmf-ui-chat-form';
import {DataPayment} from '@app/models/data-payment';
import {UserService} from "@app/services/common/user.service";
import {User} from "@co/gmf-ui-common";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  initPayment = false;
  paiementError = false;
  dataPayment: DataPayment;
  private _refContratInternet: string;
  private _codeAppelant: string;
  private _productName: string;
  private _donneesAccesSips: DonneesAccesSips;
  private _productInfoTracking: any = [];
  private _societaireGMF: string;

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _userService: UserService,
              private _translate: TranslateService,
              private _paymentService: PaymentService,
              private _contratService: ContratService,
              private mtCaptchaService: MtCaptchaService,
              private _commonService: CommonService,
              private _angularticsTagcoService: AngularticsTagcoService,
              private _sanitizer: DomSanitizer) {
    this._translate.use('fr');

    this._route.queryParams.subscribe(params => {
      if (this._router.url.startsWith('/paiement/error')) {
        console.error('params paiements', params);
        this.paiementError = true;
      }
      if (params.refContrat) {
        this._refContratInternet = params.refContrat;
        console.log('refcontratInternet Param : ', this._refContratInternet);
      }

      const state = this._router.getCurrentNavigation().extras.state;
      if (state) {
        this._refContratInternet = state.refContratInternet;
        this._codeAppelant = state.codeAppelant;
        this._productName = state.productRef;
        this.initPayment = true;
      }

    });
  }

  ngOnInit() {

    if (this._userService.isAuthenticated()){
      this._societaireGMF = CLIENT_AUTHENTIFIE_OK;
    }
    if (this.initPayment) {
      if (this._userService.isAuthenticated()) {
        this._userService.getUserInformations().subscribe((user: User) => {
          this._paymentService.getInitPayment(this._refContratInternet, this._codeAppelant,
            user.cleSocietaire + user.refSocietaire).subscribe(donneesAccesSips => {
            this._donneesAccesSips = donneesAccesSips;
            console.log(this._donneesAccesSips.contentHtml);
          }, error => {
            console.log(`error loading donneesAccesSips`, error);
          });
        });
      }else {
        this._paymentService.getInitPayment(this._refContratInternet, this._codeAppelant, 'N').subscribe(donneesAccesSips => {
          this._donneesAccesSips = donneesAccesSips;
          console.log(this._donneesAccesSips.contentHtml);
        }, error => {
          console.log(`error loading donneesAccesSips`, error);
        });
      }

    } else {
      this.dataPayment = JSON.parse(sessionStorage.getItem(KEY_DATA_PAYMENT));

      this._contratService.getInfoPayment(this._refContratInternet).subscribe(infoPayment => {
        this.dataPayment.infoPaiement = infoPayment;
        let libelleContrat = '';

        if (this.dataPayment.typeEtude != null) {
          if (this.dataPayment.typeEtude === TYPE_CONTRAT_INFIRMIER) {
            libelleContrat = this._translate.instant(this.dataPayment.libelleProduit + '.nom-affichage-contrat-infirmiers');
          } else {
            libelleContrat = this._translate.instant(this.dataPayment.libelleProduit + '.nom-affichage-contrat-aides-soignants');
          }
        } else {
          libelleContrat = this._translate.instant(this.dataPayment.libelleProduit + '.nom-affichage-contrat');
        }
        this.dataPayment.libelleContrat = libelleContrat;
      }, error => {
        console.log(`error getting Info Payment`, error);
      });
    }
    // if (this._codeAppelant === 'ES' || this.dataPayment.codeAppelant === 'ES') {

    this._commonService.setHideFooter(true);
    // }
    this.loadPaymentTracking(this.dataPayment.codeAppelant);
  }

  getSipsHtmlWithSanitize(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this._donneesAccesSips.contentHtml);
  }

  redirectToSpace() {
    sessionStorage.clear();
    if (this._societaireGMF === CLIENT_AUTHENTIFIE_OK) {
      this._commonService.redirectToClientSpace();
    } else {
      this._commonService.redirectToProspectSpace();
    }
  }

  formatPrice(value: string): string {
    return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(parseFloat(value));
  }

  private loadPaymentTracking(codeAppelant: string): void {
    this._commonService.getLocalData(`${PATH_BASE_PRODUCT_TRACKING_CONF}/all-payment-tracking.json`).subscribe(data => {
      this._productInfoTracking = data;
      this.trackingPayment(codeAppelant);
    }, error => {
      console.log('error loading payment tracking', error);
    });
  }

  private trackingPayment(codeAppelant: string): void {
    let infoTracking;
    if (this.initPayment) {
      infoTracking = this._productInfoTracking.find(p => p.ref === (this._productName + MODE_PAYMENT));
      this.trackSinglePage(infoTracking, codeAppelant);
      sessionStorage.setItem(KEY_DATA_LAYER, JSON.stringify(this._angularticsTagcoService.dataLayer));
      sessionStorage.setItem(KEY_PRODUCT_NAME, JSON.stringify(this._productName));
    } else {
      this._productName = JSON.parse(sessionStorage.getItem(KEY_PRODUCT_NAME));
      if (this.paiementError) {
        infoTracking = this._productInfoTracking.find(p => p.ref === (this._productName + PAYMENT_KO));
      } else {
        infoTracking = this._productInfoTracking.find(p => p.ref === (this._productName + PAYMENT_OK));
      }
      this._angularticsTagcoService.dataLayer = JSON.parse(sessionStorage.getItem(KEY_DATA_LAYER));
      this.trackSinglePage(infoTracking, codeAppelant);
    }
  }

  private trackSinglePage(infoTracking: any, codeAppelant: string): void {
    if (infoTracking) {
      const customDataLayer: any = {};
      customDataLayer.page_niveau3 = infoTracking.pageNiveau3;
      customDataLayer.pagename = '/' + codeAppelant + infoTracking.pageName;
      customDataLayer.pagename_prec = '/' + codeAppelant + infoTracking.pagename_prec;
      customDataLayer.page_tunnel = 'fin_' + codeAppelant;
      this._angularticsTagcoService.trackingSinglePage(customDataLayer);
    }
  }
}
