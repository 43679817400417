import {ComponentFactoryResolver, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Choice, CoGmfCfPopinComponent, InfoPopIn, PopinService} from '@co/gmf-ui-chat-form';
import {environment} from '@env/environment';
import {ContratSpecificite} from '@app/models/contrat-specifique';
import {PopinInfoRenonciationComponent} from '@app/shared/components/popin-info-renonciation/popin-info-renonciation.component';
import {BaseTarifComponent} from '@app/produits/base/base-tarif.component';
import {NamePopinEnum} from '@app/models/name-popin.enum';
import {ProductService} from '@app/services/produits/product.service';
import {DataPayment} from '@app/models/data-payment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private _choice: Choice;
  public showContent$ = new BehaviorSubject<boolean>(false);

  constructor(private _http: HttpClient,
              private popinService: PopinService,
              private resolver: ComponentFactoryResolver) {
    this.showContent$.next(false);
  }

  // private _hideFooter = false;
  private _hideFooterSubject = new Subject<boolean>();
  hideFooter$ = this._hideFooterSubject.asObservable();

  setHideFooter(item: boolean) {
    this._hideFooterSubject.next(item);
   }

 /* get hideFooter(): boolean {
    return this._hideFooter;
  }

  set hideFooter(value: boolean) {
    this._hideFooter = value;
  }*/



  /* Deprecated: Use the utilsService from the library gmf-ui-chat-form */
  getLocalData = (url: string): Observable<any> => {
    return this._http.get(url);
  };

  /* Creates a choice to be sent to co-gmf-cf-form */
  createChoice(label: string, value: string, nextQuestionRef: string): Choice {
    const choice = {} as Choice;
    choice.label = label;
    choice.value = value;
    choice.nextQuestionRef = nextQuestionRef;

    return choice;
  }

  /* Return true if a key is contained in the array passed as parameter; Otherwise false. */
  containKeySpecificite(array: any, key: string, value?: string): boolean {
    if (value != null) {
      return array.some(item => item.cle === key && item.valeur === value);
    } else {
      return array.some(item => item.cle === key);
    }
  }

  getValueSpecificite(listSpecificite: ContratSpecificite[], key: string): ContratSpecificite {
    return listSpecificite.find(item => item.cle === key);
  }

  containValue(array: any, value: string): boolean {
    return array.some(item => item === value);
  }

  deleteKeySpecificite(array: any, key: string): void {
    const index = array.findIndex(item => item.cle === key);
    if (index > -1) {
      return array.splice(index, 1);
    }
  }

  /**
   * Return the type or the name of the tariff. Example if the label is 'Formule Apel', the type would be 'Formule'
   * and the name would be 'Apel'.
   *
   * @param label text composed by the type and the name of tariff.
   * @param isType True, if we want to get the first part of the label; otherwise the name of the tariff would be returned.
   * @returns return the type or the name of the tariff.
   */
  getLabelTariff(label: string, isType: boolean): string {
    if (label != null) {
      const index = label.indexOf(' ');
      return isType ? label.substring(0, index) : label.substring(index + 1, label.length);
    }
  }

  /**
   * Parse a string value that could be an integer ou float value.
   * @param value to be parsed
   * @returns number
   */
  parseNumber(value: string): number {
    if (Number.isNaN(Number.parseFloat(value))) {
      return 0;
    }
    return Number.parseFloat(value);
  }

  /**
   * Return true if the device is a mobile of tablet; otherwise false.
   * @returns true if the device is a mobile of tablet; otherwise false
   */
  isMobileTabletMode(): boolean {
    if (window != null) {
      return window.screen.width < 1261;
    }
  }

  saveDataShowPayment(productService: ProductService): DataPayment {
    const dataPayment = {} as DataPayment;
    dataPayment.civilite = productService.contrat.civilite;
    dataPayment.nom = productService.contrat.nom;
    dataPayment.libelleProduit = productService.contrat.produit.libelleProduit;
    dataPayment.societaireGMF = productService.contrat.societaireGMF;
    dataPayment.typeEtude = null;
    dataPayment.dateEffet = productService.contrat.dateEffet;
    dataPayment.codeAppelant = productService.codeAppelant;
    return dataPayment;
  }

  /* Redirect to client space page */
  public redirectToClientSpace() {
    window.location.href = environment.urlEspaceSoc;
  }

  /* Redirect to prospect space page */
  public redirectToProspectSpace() {
    window.location.href = environment.urlEspaceProspect;
  }

  /* To Uppercase all word */
  public uppercaseAllWord(chaine: string) {
    return chaine.toLowerCase().replace(/(^|\s|\-)([a-zéèêë])/g, (u, v, w) => {
      return v + w.toUpperCase();
    });
  }

  openPopinRenonciation(baseTarifComponent: BaseTarifComponent) {
    baseTarifComponent.viewPopinRef.clear();

    const popinFactory = this.resolver.resolveComponentFactory(PopinInfoRenonciationComponent);
    baseTarifComponent.viewPopinRef.createComponent(popinFactory);

    // open fancybox dialog
    this.popinService.openPopin({
      selector: '#popinRenonciation', afterCloseFct: () => baseTarifComponent.exitPopIn$.next(NamePopinEnum.POPIN_RENONCIATION)
    });
  }


  openPopinModal(baseTarifComponent: BaseTarifComponent, infoPopIn: InfoPopIn, namePopinEnum: NamePopinEnum) {
    baseTarifComponent.viewPopinRef.clear();

    const popinFactory = this.resolver.resolveComponentFactory(CoGmfCfPopinComponent);
    const popinComponent = baseTarifComponent.viewPopinRef.createComponent(popinFactory);
    popinComponent.instance.infoPopin = infoPopIn;

    // open fancybox dialog
    this.popinService.openPopin({
      selector: '#rejectPopin', afterCloseFct: () => baseTarifComponent.exitPopIn$.next(namePopinEnum)
    });
  }

  forceHidingFooter(){
    const footer = document.getElementsByClassName('zo-footer')[0];
    if (footer !== undefined){
      footer.setAttribute('style', 'display: none !important');
    }
  }
}
