import {Environment} from '@env/environment.interfaces';
import * as merge from 'lodash.merge';

let properties: Environment = {
  production: true,
  router: {
    enableTracing: false
  },
  security: {
    endpointUrl: 'https://api-tarif-assurance.gmf.fr/cap-us-usine-petits-produits-internet/api',
    origin: '0',
    population: '00',
  },
  logCentral: {
    isEnable: true,
    handlerLogUrl: ''
  },
  urlBack: '',
  urlEspaceSoc: 'http://espace-assure.gmf.fr',
  urlEspaceProspect: 'http://www.gmf.fr',
  urlRdv: 'https://www.gmf.fr/agences-gmf',
  urlCallBack: 'https://contact.gmf.fr/se-faire-rappeler',
  urlElevesEp: 'http://tarif-assurance.gmf.fr/eleves-infirmiers-aides-soignants',
  urlApiEp: 'http://tarif-assurance.gmf.fr/personnelle-infirmiers-aides-soignants',
  urlVieProEp: 'http://tarif-assurance.gmf.fr/assurance-vie-pro',
  urlEluEp: 'http://tarif-assurance.gmf.fr/personnelle-elu',
  apiSocietaireUrl: '',
  isEsMode: false,
  fromEs: false,
  angularticsTagco: {
    appId: '2392', // required
    jsName: 'tc_GMF_', // required
    eventFunctionName: 'tc_events_1', // required
    eventOnName: 'eventGA',
    prod: true,
    preventHitOnInit: true,
    dataLayer: {
      env_template: 'usine_produits', // Type de page
      env_work: 'production', // Environnement de travail
      env_marque: 'gmf', // marque appelant la plateforme de service
      env_language: 'fr'
    },
    jsIndexFile1: '3',
    jsIndexFile2: '1',
    jsIndexFile3: '5'
  },
  codeAppelant: ''
};

export const environment: Environment = merge(properties, window['COVEUSE_CONFIG']);
