<div *ngIf="errorCode" class="bodyWrapper" style="margin-bottom: 40px">
  <div class="errorWrapper">
    <div class="rowConversation">
      <div class="avatar error"></div>
      <div class="bubbleQuestion" style="margin-top: 25px">
        <div class="arrowBubbleQ"></div>
        <div>
          <p class="errorCodeLabel">{{errorCode}} !</p>
          <p class="errorDetails"><b>{{'error.' + errorCode | translate}}<br>Nous sommes assurément désolés !</b></p>
        </div>

        <!-- <input type="button" value="Faire une nouvelle demande de tarif" class="button" [routerLink]="['/simulation']"/> -->
      </div>

    </div>
  </div>
</div>
