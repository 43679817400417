import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {APPLICATION_JSON, CONTENT_TYPE, PHONE_NUMBER_OPTIONAL} from '@app/models/constants';
import {Observable} from 'rxjs';
import {Contrat} from '@app/models/contrat';
import {CodeAppelantEnum} from '@app/models/code-appelant.enum';
import {CookieService} from 'ngx-cookie-service';
import {CodeAvancementContratEnum} from '@app/models/code-avancemment.contrat.enum';
import * as moment from 'moment';
import {InfoPayment} from '@app/models/info-payment';

@Injectable({
  providedIn: 'root'
})
export class ContratService {

  private _endPoint = `${environment.urlBack}/api`;

  constructor(private _httpClient: HttpClient,
              private _cookieService: CookieService) {
  }

  getContratByRef = (refContratInternet: string): Observable<Contrat> => {
    return this._httpClient.get<Contrat>(this._endPoint + '/contrat/' + refContratInternet, {params: new HttpParams()});
  }

  getInfoPayment(refContratInternet: string): Observable<InfoPayment> {
    return this._httpClient.get<InfoPayment>(this._endPoint + '/contrat/infosPaiement/' + refContratInternet);
  }

  createContrat = (contrat: Contrat, token: string, codeAppelant: CodeAppelantEnum): Observable<Contrat> => {
    const contratInput: any = Object.assign({}, contrat);
    delete contratInput.formuleChoisie.listeGaranties;
    const headers = new HttpHeaders().set(CONTENT_TYPE, APPLICATION_JSON)
      .set('token_mtcaptcha', token);
    const params = new HttpParams().append('codeAppelant', codeAppelant.toString());
    return this._httpClient.post<Contrat>(this._endPoint + '/contrat/', contratInput, {headers, params});
  }

  envoyerMail = (contrat: Contrat, codeAppelant: CodeAppelantEnum, callAOC: boolean, token: string): Observable<Contrat> => {
    const contratInput: any = Object.assign({}, contrat);
    const params = new HttpParams().append('codeAppelant', codeAppelant.toString()).append('callAOC', callAOC.toString());

    const headers = new HttpHeaders().set(CONTENT_TYPE, APPLICATION_JSON)
      .set('token_mtcaptcha', token)
      .set('_ga', this._cookieService.get('_ga'));
    return this._httpClient.post<Contrat>(this._endPoint + '/contrat/envoyerMail', contratInput, {headers, params});
  }

  envoyerLeadsAoc = (contrat: Contrat, codeAvancementContratEnum: CodeAvancementContratEnum,
                     codeAppelant: CodeAppelantEnum, token: string): Observable<Contrat> => {
    // Generation RefContratInternet
    contrat.refContratInternet = this.generateRandomNDigits(6);
    const contratInput: any = Object.assign({}, contrat);
    if (contrat.telephone === PHONE_NUMBER_OPTIONAL) {
      contratInput.telephone = '';
    }
    const params = new HttpParams().append('codeAppelant', codeAppelant.toString())
      .append('codeAvancementContrat', codeAvancementContratEnum.toString());

    const headers = new HttpHeaders().set(CONTENT_TYPE, APPLICATION_JSON)
      .set('token_mtcaptcha', token)
      .set('_ga', this._cookieService.get('_ga'));
    return this._httpClient.post<Contrat>(this._endPoint + '/contrat/envoyerLeadsAoc', contratInput, {headers, params});
  }

  generateRandomNDigits = (n) => {
    const check = moment();
    const month = check.format('MM');
    const day = check.format('DD');
    const year = check.format('YYYY');
    const key = ('UP').concat(year).concat(month).concat(day);
    return key.concat(Math.round((Math.pow(36, n + 1) - Math.random() * Math.pow(36, n))).toString(36).slice(1)).toUpperCase();
  }

}
