import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {UuidInterceptorModule} from '@coveuse/uuid-interceptor';
import {SecurityModule, SecurityService} from '@coveuse/security';
import {LoadingSpinnerModule} from '@coveuse/loading-spinner';
import {HttpErrorNotifierModule, HttpErrorNotifierService} from '@coveuse/http-error-notifier';
import {ToasterModule, ToasterNotifierService} from '@coveuse/toaster';
import {LogCentralModule, LogCentralService} from '@coveuse/log-central';
import {StorageModule} from '@coveuse/storage';

import {environment} from '@env/environment';
import {AngularticsTagco, AngularticsTagcoModule} from '@coveuse/angulartics-tagco';

@NgModule({
  imports: [
    HttpClientModule,
    UuidInterceptorModule,
    LogCentralModule,
    SecurityModule.forRoot(),
    StorageModule,
    HttpErrorNotifierModule,
    LoadingSpinnerModule,
    ToasterModule,
    AngularticsTagcoModule.forRoot(),
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModules: CoreModule,
    securityService: SecurityService,
    logCentralService: LogCentralService,
    toasterNotifierService: ToasterNotifierService,
    httpErrorNotifierService: HttpErrorNotifierService,
    angularticsTagco: AngularticsTagco
  ) {
    if (parentModules) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
    securityService.configure(environment.security);
    logCentralService.setConfig(environment.logCentral);
    angularticsTagco.configure(environment.angularticsTagco);
  }
}
