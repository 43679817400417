import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popin-info-renonciation',
  templateUrl: './popin-info-renonciation.component.html',
  styleUrls: ['./popin-info-renonciation.component.scss']
})
export class PopinInfoRenonciationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  closePopIn() {
    // TODO
  }

}
