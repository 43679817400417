import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from '@app/core/core.module';
import {SharedModule} from '@app/shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PaymentComponent} from '@app/payment/payment.component';
import {PopinInfoRenonciationComponent} from '@app/shared/components/popin-info-renonciation/popin-info-renonciation.component';
import {HttpInterceptorErrorService} from '@app/services/common/http-interceptor-error.service';
import {CookieService} from 'ngx-cookie-service';
import {TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorComponent} from '@app/error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    PaymentComponent,
    ErrorComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    SharedModule.forRoot(),
    TranslateModule,
    AppRoutingModule
  ],
  exports: [SharedModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorErrorService, multi: true },
    CookieService
  ],
  entryComponents: [
    PopinInfoRenonciationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


