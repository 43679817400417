import {AfterContentInit, Component, OnInit, ViewContainerRef, OnDestroy} from '@angular/core';
import {ToasterService} from '@coveuse/toaster';
import {environment} from '@env/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {SecurityService} from '@coveuse/security';
import {CommonService} from '@app/services/common/common.service';
import { UtilsService } from '@co/gmf-ui-common';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  // URI to exclude in security service
  EXLUDED_URI = [/geo.api.gouv.fr/];

  // To be able to use environment in html without being undefined
  environment = environment;
  hideFooter: boolean;
  hideFooterSubscription$: Subscription;

  public constructor(private _utilsService: UtilsService,
                     private _securityService: SecurityService,
                     private toaster: ToasterService,
                     private route: ActivatedRoute,
                     private router: Router,
                     public commonService: CommonService,
                     vcr: ViewContainerRef) {
    this.toaster.setRootViewContainerRef(vcr);
  }

  // For tracking toolbar and callback_tchat
  public trackClickEvent = () => {
  }

  ngAfterContentInit() {
    const currentUrl = `${environment.urlEspaceSoc}?nextUrl=${window.location.href}`;

    // check if user is connected and it has ES in query param or is in restitution page and is connected without param Query ES in url
    if (this._securityService.isAuthenticated() && this._utilsService.hasEsParam('codeAppelant')) {
      // Mode ES
      this.commonService.showContent$.next(true);
      environment.isEsMode = true;
    } else if (!this._securityService.isAuthenticated() && this._utilsService.hasEsParam('codeAppelant')) {
      // Redirect to connection page if not authenticated
      window.location.href = currentUrl;
    } else {
      this.commonService.showContent$.next(true);
    }
  }

  ngOnInit() {
    // It's necessary to call publique external URI as : GEO GOUV,...

    this._securityService.addExclusions(this.EXLUDED_URI);
    this.getCodeAppelant();
    this.hideFooterSubscription$ = this.commonService.hideFooter$.subscribe(
      (x: boolean) => (this.hideFooter = x)
    );
  }

  ngOnDestroy(){
   this.hideFooterSubscription$.unsubscribe();
  }

  getCodeAppelant(): void {
    this.route.queryParams.subscribe(params => {
      console.log('params appelé', params);
      if (params.codeAppelant) {
        environment.codeAppelant = params.codeAppelant;
        console.log('environment.codeAppelant : ', environment.codeAppelant);
      }
    });
  }
}

