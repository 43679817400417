import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PaymentComponent} from '@app/payment/payment.component';
import {ErrorComponent} from '@app/error/error.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/personnelle-elu',
    pathMatch: 'full'
  },
  {
    path: 'personnelle-elu',
    loadChildren: () => import('./produits/apel/apel.module').then(m => m.ApelModule)
  },
  {
    path: 'personnelle-infirmiers-aides-soignants',
    loadChildren: () => import('./produits/api/api.module').then(m => m.ApiModule)
  },
  {
    path: 'eleves-infirmiers-aides-soignants',
    loadChildren: () => import('./produits/eieas/eieas.module').then(m => m.EieasModule)
  },
  {
    path: 'assurance-vie-pro',
    loadChildren: () => import('./produits/vie-pro/vie-pro.module').then(m => m.VieProModule)
  },
  {
    path: 'paiement',
    component: PaymentComponent
  },
  {
    path: 'paiement/error',
    component: PaymentComponent
  },
  {
    path: 'error/:errorCode',
    component: ErrorComponent
  },
  {
    path: '**', redirectTo: '/error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
