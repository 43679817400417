import {Injectable} from '@angular/core';
import {AngularticsTagco} from '@coveuse/angulartics-tagco';
import {environment} from '@env/environment';
import {CommonService} from '@app/services/common/common.service';
import {Question, Response} from '@co/gmf-ui-chat-form';
import {AbstractTrackingService} from '@app/services/tracking/abstract-tracking.service';
import {Angulartics2} from 'angulartics2';
import {ProductNameEnum} from '@app/models/product-name.enum';
import {
  PATH_BASE_PRODUCT_TRACKING_CONF,
  PATH_EXCEPTION_TRACKING,
  PREFIX_EXCEPTION_TRACKING_FILE
} from '@app/models/constants';
import { UtilsService } from '@co/gmf-ui-common';

@Injectable({
  providedIn: 'root'
})
export class AngularticsTagcoService {

  private _trackingService: AbstractTrackingService;
  private _exceptionRefQuestions: any = [];

  constructor(private _angularticsTagco: AngularticsTagco,
              private _angulartics2: Angulartics2,
              private _utilsService: UtilsService,
              private _commonService: CommonService) {
    this.loadCommonExceptionRefQuestionTracking();
    this._angulartics2.settings.pageTracking.autoTrackVirtualPages = false;
  }

  private _dataLayer: any;

  get dataLayer(): any {
    return this._dataLayer;
  }

  set dataLayer(value: any) {
    this._dataLayer = value;
  }

  private _lastResponseValue: string;

  get lastResponseValue(): string {
    return this._lastResponseValue;
  }

  set lastResponseValue(lastResponse: string) {
    this._lastResponseValue = lastResponse;
  }

  private _lastPageName: string;

  get lastPageName(): string {
    return this._lastPageName;
  }

  set lastPageName(value: string) {
    this._lastPageName = value;
  }

  initDataLayer(productName: string, question: Question, hasConfigFile: boolean = false, codeAppelant: string): void {
    if (!this._dataLayer) {
      this._dataLayer = environment.angularticsTagco.dataLayer;
    }
    this._dataLayer.env_channel = this.getEnvChannel();

    if (productName != null && hasConfigFile) {
      this.loadDataLayerConf(productName, question, codeAppelant);
    } else if (this.hasTrackingInfos(question)) {
      this.manageDataLayer(question, null, codeAppelant);
    }
  }

  trackingResponse(response: Response, question: Question, codeAppelant: string): void {
    if (this.hasTrackingInfos(question)) {
      this.manageDataLayer(question, response, codeAppelant);
    } else if (response && !response.modification) {
      this.getValuesFromResponse(response);
    }
  }

  trackingEvent(action: string, category: string, label: string): void {
    this._angularticsTagco.eventTrack(action, label, category);
  }

  trackingSinglePage(tempDataLayer: any): void {
    this._dataLayer.page_variable_choix = this.normalizeTrackingLabel(this._lastResponseValue);
    this._dataLayer.pagename_prec = this._lastPageName;
    this._dataLayer = Object.assign({}, this._dataLayer, tempDataLayer);
    this._angularticsTagco.updatePartialDataLayer(this._dataLayer);
  }

  /**
   * Add the product service having the implementation of the custom tracking.
   */
  addCustomTrackingService(trackingService: AbstractTrackingService): void {
    this._trackingService = trackingService;
  }

  /**
   * Clean the data layer value for the question been modified.
   * This question is referenced by the nextRef value.
   */
  cleanDataLayerValue(ref: string, screen: string): void {
    const tempDataLayer = this._trackingService.cleanCustomTracking(ref, screen);
    this._dataLayer = Object.assign({}, this._dataLayer, tempDataLayer);
    this._dataLayer.page_variable_choix = '';
  }

  /**
   * load a set of question references whose values must not be tracked in the page_variable_choix variable.
   */
  loadExceptionRefQuestionTracking(productName: ProductNameEnum): void {
    this._commonService.getLocalData(`${PATH_EXCEPTION_TRACKING}/${productName}-${PREFIX_EXCEPTION_TRACKING_FILE}.json`).subscribe(data => {
      this._exceptionRefQuestions = this._exceptionRefQuestions.concat(data);
    }, error => {
      console.log('error loading exception value for ref question common tracking', error);
    });
  }

  private manageDataLayer(question: Question, response: Response, codeAppelant: string) {
    if (response && response.modification) {
      this.cleanDataLayerValue(question.ref, question.typeScreen);
    } else {
      this.getValuesFromResponse(response);
      this.fillDataLayer(question, codeAppelant);
      this._angularticsTagco.updatePartialDataLayer(this._dataLayer);
    }
  }

  private getValuesFromResponse(response: Response): void {
    if (response != null) {
      const value = response.label != null && response.label.length > 0 ? response.label : response.value;
      this._lastResponseValue = this._exceptionRefQuestions.some(e => e.ref === response.ref) ? null
        : this.normalizeTrackingLabel(value, response.ref);
      this._lastPageName = ((response.trackingData) ? response.trackingData.pageName : this._lastPageName);
    }
  }

  private normalizeTrackingLabel(val: string, ref?: string): string {
    let ret = val;
    if (this._utilsService.isNotEmpty(ret) && !this.isDateRef(ref)) {
      ret = this._utilsService.cleanAccentuatedCharacters(ret);
      ret = ret.toLowerCase().split(' ').join('_');
    }
    return ret;
  }

  private loadDataLayerConf(productName: string, question: Question, codeAppelant: string): void {
    this._commonService.getLocalData(`${PATH_BASE_PRODUCT_TRACKING_CONF}/${productName}-conf-tracking.json`).subscribe(data => {
      if (data != null) {
        this._dataLayer = Object.assign(this._dataLayer, data);
        this.manageDataLayer(question, null, codeAppelant);
      }
    }, error => {
      console.log(`error loading ${productName} conf tracking`);
    });
  }

  private getEnvChannel(): string {
    if (window != null) {
      // Desktop et tablette > 768 (iso immersion). On ne tient pas compte de la rotation du smartphone.
      return window.screen.width > 768 ? 'desktop' : 'mobile';
    }
  }

  private hasTrackingInfos(question: Question): boolean {
    return question != null && question.trackingData.pageName !== undefined &&
    question.trackingData.pageNiveau3 !== undefined && question.trackingData.pageNiveau3 !== undefined;
  }

  private fillDataLayer(question: Question, codeAppelant: string): void {
    this._dataLayer.page_variable_choix = this._lastResponseValue;
    this._dataLayer.pagename_prec = this._lastPageName;
    const tempDataLayer = this._trackingService.customTracking(question.ref, question.typeScreen, this._dataLayer, question.trackingData);

    this._dataLayer = Object.assign({}, this._dataLayer, tempDataLayer);
    this._dataLayer.env_espace = codeAppelant;
    this._dataLayer.page_niveau3 = question.trackingData.pageNiveau3;
    this._dataLayer.page_niveau2 = question.trackingData.pageNiveau2;
    this._dataLayer.pagename = question.trackingData.pageName;
    if (codeAppelant === 'ES') {
      this._dataLayer.page_tunnel = question.trackingData.pageTunnelEs + codeAppelant;
    } else {
      this._dataLayer.page_tunnel = question.trackingData.pageTunnelEp + codeAppelant;
    }
    this._dataLayer.prod_url = window.location.href;

    if (question.trackingData.prodUrl != null) {
      this._dataLayer.prod_url = question.trackingData.prodUrl;
    }
  }

  private loadCommonExceptionRefQuestionTracking(): void {
    this._commonService.getLocalData(`${PATH_EXCEPTION_TRACKING}/common-${PREFIX_EXCEPTION_TRACKING_FILE}.json`).subscribe(data => {
      this._exceptionRefQuestions = data;
    }, error => {
      console.log('error loading exception value for ref question common tracking', error);
    });
  }

  private isDateRef(ref: string): boolean {
    return ref && ref.match(/date/) != null;
  }

}
