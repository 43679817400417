import {Injectable} from '@angular/core';
import {SecurityService} from '@coveuse/security';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { User } from '@co/gmf-ui-common';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  societaire: User;
  private _currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>({} as User);

  constructor(private _securityService: SecurityService,
              private httpClient: HttpClient) {   }

  getUserData() {
    return this.httpClient.get<any>(`${environment.apiSocietaireUrl}/societaires`)
      .pipe(map((user: User) => {
        if (user) {
          // store user details in BehaviourSubject
          console.log('user', user);
          this._currentUserSubject.next(user);
          this.societaire = user;
        }
        return user;
      }));
  }

  isAuthenticated(): boolean {
    return this._securityService.isAuthenticated();
  }

  get currentUserSubjectValue(): User {
    return this._currentUserSubject.value;
  }

  /**
   * Method to get user informations
   */
  getUserInformations(): Observable<any> {
    return this.httpClient.get<any>(environment.apiSocietaireUrl + '/societaires', this.httpOptions);
  }

  /**
   * Method to get user situation (and last connexion date)
   */
  getUserSituation(): Observable<any> {
    return this.httpClient.get<any>(environment.apiSocietaireUrl + '/societaires/situation', this.httpOptions);
  }

  /**
   * Method to get label name
   */
  getFormattedUserName() {
    console.log('societaire', this.societaire);
    if (this.societaire != null) {
      const civilite = this.societaire.sexe === 'M' ? 'M ' : 'Mme ';
      return `${civilite}${this.societaire.nom}`;
    } else {
      return'';
    }

  }
}
