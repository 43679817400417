<div class="popInInfoRenonciation" id="popinRenonciation" class="block popin">
  <div class="popin-title">
    <span>VOTRE DROIT A RENONCIATION</span>
  </div>
  <div class="infoPopIn">
    <div class="phrase">
      <p>Dans le cadre d'une souscription à distance, vous disposez d'un droit de renonciation dans les conditions suivantes :<br/>
      Après avoir souscrit votre contrat d'assurance et reçu toutes les informations contractuelles sur support écrit
      ou durable, vous disposez d'un délai de 14 jours pour renoncer à votre contrat d'assurance.<br/>
        Il vous suffira d'envoyer votre lettre de renonciation en recommandé avec avis de réception à : </p>
      <p>GMF Service Renonciation<br>
        45930 ORLEANS Cedex 09</p>
      <p>"Je soussigné(e) (nom, prénom, adresse complète) désire renoncer au contrat souscrit à distance
        (nom du contrat, n° de contrat, date de souscription).<br/>
        Date et signature"
      </p>
      <p>Vos garanties cesseront à compter de la réception de votre lettre de renonciation et vous serez remboursé
        dans les 30 jours des sommes déjà versées, excepté celles correspondant à la période de garantie déjà écoulée.
      </p>
    </div>
  </div>
</div>

