import {NgModule, ModuleWithProviders, LOCALE_ID} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GmfUiChatFormModule} from '@co/gmf-ui-chat-form';
import {InformationsTarifairesComponent} from '@app/shared/components/informations-tarifaires/informations-tarifaires.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AngularticsTagcoModule} from '@coveuse/angulartics-tagco';
import {PopinInfoRenonciationComponent} from '@app/shared/components/popin-info-renonciation/popin-info-renonciation.component';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ConfigService, UtilsService, ConfigEnvironment, GmfUiCommonModule } from '@co/gmf-ui-common';
import { CommonService } from '@app/services/common/common.service';
import { environment } from '@env/environment';
import { CONFIG } from '@app/models/constants';


const configEnvironment: ConfigEnvironment = {
  urlApiSocietaire: environment.apiSocietaireUrl,
  urlEspaceSocietaire: environment.urlEspaceSoc,
  contextCallerId: undefined
};

@NgModule({
  imports: [
    CommonModule,
    GmfUiCommonModule,
    GmfUiChatFormModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'fr'
    }),
  ],
  declarations: [
    InformationsTarifairesComponent,
    PopinInfoRenonciationComponent
  ],
  exports: [
    CommonModule,
    GmfUiChatFormModule,
    GmfUiCommonModule,
    InformationsTarifairesComponent,
    PopinInfoRenonciationComponent,
    TranslateModule,
    AngularticsTagcoModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        CommonService,
        ConfigService,
        UtilsService,
        {provide: LOCALE_ID, useValue: 'fr-FR'},
        {provide: CONFIG, useValue: configEnvironment}
      ]
    }
  }
}

// required for AOT compilation
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
