import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/internal/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorErrorService implements HttpInterceptor {

  constructor(private _router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        const errorCode = error.status === 0 ? 503 : error.status;
        if (!error.url.endsWith('/api/users/infos')) {
          this._router.navigate(['/error/' + errorCode]);
        }
        return throwError(error.error);
      })
    );
  }
}
