import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DonneesAccesSips} from '@app/models/donnees-acces-sips';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env/environment';
import {CommonService} from '@app/services/common/common.service';
import {UserService} from '@app/services/common/user.service';
import {  User } from '@co/gmf-ui-common';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private _endPoint = `${environment.urlBack}/api`;

  constructor(private _httpClient: HttpClient,
              private _userService: UserService,
              private _commonService: CommonService) { }

  getInitPayment = (refContratInternet: string, codeAppelant: string, refSocietaire: string): Observable<DonneesAccesSips> => {
    const headersParams = new HttpHeaders().set('isMobileTabletteMode', this._commonService.isMobileTabletMode().toString());
      return this._httpClient.get<DonneesAccesSips>(this._endPoint + '/paiement/' + refContratInternet + '/' + codeAppelant + '/' + refSocietaire,
        { headers: headersParams });

  }
}
