
<ng-container *ngIf="this.initPayment">
  <div class="homeContainer">
    <div [innerHTML]="getSipsHtmlWithSanitize()"></div>
  </div>
</ng-container>
<ng-container *ngIf="!this.initPayment && !this.paiementError">
  <div [ngClass]="(this.dataPayment.codeAppelant =='ES')?'modeEs page confirmation':'page'">
    <h1>Votre souscription au contrat {{this.dataPayment.libelleContrat}}</h1>
    <div class="wrapEnTete">
      <div class="enTeteInfo">
        <h2>CONFIRMATION DE LA SOUSCRIPTION</h2>
        <div class="civilite">
          <span>{{'civilite.' + this.dataPayment.civilite | translate}} {{this.dataPayment.nom}},</span>
        </div>
        <p>Nous vous remercions de votre confiance et vous confirmons la souscription de votre contrat {{this.dataPayment.libelleContrat}}.</p>
        <p>Celui-ci prendra effet le {{this.dataPayment.dateEffet}} à 0 heure.</p>
        <div>Numéro de transaction : {{this.dataPayment.infoPaiement.idTransaction}}</div>
        <div>Montant de la transaction : {{formatPrice(this.dataPayment.infoPaiement.montantPaiement)}}</div>
        <br/>
        <p *ngIf="this.dataPayment.libelleProduit!='eieas' && this.dataPayment.libelleProduit!='api'">Nous vous envoyons l'ensemble des documents contractuels par courrier dans les prochains jours.</p>
        <p *ngIf="this.dataPayment.libelleProduit=='api'">Vous recevrez les documents contractuels par courrier dans les prochains jours.</p>
        <br/>
        <h3 class="h3-titleNote">A noter</h3>
        <br/>
        <p>{{this.dataPayment.libelleProduit=='vie-pro'?'Garanties Protection juridique professionnelle':'Garanties Responsabilité Civile'|json}} et {{this.dataPayment.libelleProduit=='eieas'?'Défense Pénale':this.dataPayment.libelleProduit=='vie-pro'?'Responsabilité civile personnelle':this.dataPayment.libelleProduit=='api'?'"Défense Pénale"':'Protection Juridique'|json}} : Sont couverts, les sinistres portant sur des faits ou actes dont la connaissance est postérieure à la prise d'effet du contrat.</p>
        <input type="button" value="Quitter"
               class="button"
               (click)="redirectToSpace()" />
      </div>

    </div>
  </div>
</ng-container>
<ng-container *ngIf="!this.initPayment && this.paiementError">
  <div [ngClass]="(this.dataPayment.codeAppelant =='ES')?'modeEs page confirmation':'page'">
    <h1>Votre souscription au contrat {{this.dataPayment.libelleContrat}}</h1>
    <div class="wrapEnTete">
      <div class="enTeteInfo">
        <h2>ECHEC DE LA TRANSACTION</h2>
        <div class="civilite">
          <span>{{'civilite.' + this.dataPayment.civilite | translate}} {{this.dataPayment.nom}},</span>
        </div>
        <p>Malheureusement, la transaction n'a pu aboutir pour des raisons indépendantes de notre volonté.<br/>
          Votre compte ne sera donc pas débité.</p>
        <p>
          Numéro de transaction : {{this.dataPayment.infoPaiement.idTransaction}}<br/>
          Montant de la transaction : {{formatPrice(this.dataPayment.infoPaiement.montantPaiement)}}
        </p>
        <div class="suggestion">Nous vous suggérons soit de :
          <ul>
            <li>Renouveler la transaction ultérieurement</li>
            <li>Vous rendre dans une agence GMF</li>
          </ul>
        </div>
        <input type="button" value="Quitter"
               class="button"
               (click)="redirectToSpace()" />
      </div>
    </div>
  </div>
</ng-container>
