import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ERROR_CODE} from '@app/models/constants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private translate: TranslateService) {
    this.translate.setDefaultLang('fr');
  }

  private _errorCode: string;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this._errorCode = params[ERROR_CODE];
    });
  }

  get errorCode(): string {
    return this._errorCode;
  }

  set errorCode(value: string) {
    this._errorCode = value;
  }
}
