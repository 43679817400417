// Choices
export const TYPE_FONCTION_ETABLISSEMENT = 'D\'un EPCI';
export const TYPE_FONCTION_MUNICIPAL = 'Municipale';
export const TYPE_CONTRAT_INFIRMIER = 'Y093010.003E';
export const TYPE_CONTRAT_AIDE_SOIGNANT = 'Y093010.002D';
export const TYPE_CONTRAT_All = 'all';
export const ENSEIGNEMENT_PUBLIC = '31';
export const ENSEIGNEMENT_PRIVE = '75';
export const API_PUBLIC = '30';
export const API_PRIVE = '76';
export const OUI = 'oui';
export const NON = 'non';

// Keys
export const APEL_CD_FONCTION1 = 'apel_cd_fonction1';
export const APEL_CD_FONCTION2 = 'apel_cd_fonction2';
export const APEL_NB_HAB = 'apel_nb_hab';
export const APEL_COMMUNE = 'apel_commune';
export const EIEAS_CD_CONTRAT = 'eieas_cd_contrat';
export const EIEAS_CD_COSOP = 'eieas_cd_cosop';
//export const VIE_PRO_CLUB_ENSEIGNANT = 'viepro_clubenseignant';
export const VIE_PRO_TYPE_ENSEIGNEMENT = 'viepro_type_enseignement';
export const API_APHP = 'api_aphp';
export const API_TYPE_DIPLOME = 'api_type_diplome';
export const API_DIPLOME = 'api_diplome';

// Urls
export const URL_APEL_SITUATION_TARIF = 'personnelle-elu/tarif';
export const URL_APEL_SOUSCRIPTION = 'personnelle-elu/souscription';
export const URL_EIEAS_SITUATION_TARIF = 'eleves-infirmiers-aides-soignants/tarif';
export const URL_EIEAS_SOUSCRIPTION = 'eleves-infirmiers-aides-soignants/souscription';
export const URL_VIE_PRO_SITUATION_TARIF = 'assurance-vie-pro/tarif';
export const URL_VIE_PRO_SOUSCRIPTION = 'assurance-vie-pro/souscription';
export const URL_API_SITUATION_TARIF = 'personnelle-infirmiers-aides-soignants/tarif';
export const URL_API_SOUSCRIPTION = 'personnelle-infirmiers-aides-soignants/souscription';
export const URL_PAIEMENT = 'paiement';

// Date format
export const DD_MM_YYYY = 'DD/MM/YYYY';

// ADRESSE FORMAT
export const RUE = 'rue';
export const CHEMIN = 'chemin';
export const ROUTE = 'route';
export const VOIE = 'voie';
export const AVENUE = 'avenue';
export const AV = 'av.';
export const ARRONDISSEMENT = 'arrondissement';
export const BOULEVARD = 'boulevard';
export const BOULEVARDDU = 'boulevarddu';
export const PLACE = 'place';
export const ALLEE = 'allée';
export const IMPASSE = 'impasse';

// Labels tracking
export const LABEL_EVENT_CONSULTER_OFFRES = 'consulter_nos_offres';
export const LABEL_EVENT_VOIR_OFFRES = 'voir_notre_offre';
export const LABEL_EVENT_POURSUIVRE_SOUSCRIPTION = 'poursuivre_souscription';
export const LABEL_EVENT_DOCUMENT_INFORMATION = 'document_information';
export const LABEL_EVENT_DOCUMENT_GENERALE = 'document_generale';
export const PAGE_NAME_TARIFF = '/Assurance_personnelle_elu/affichage_formules';
export const PAGE_NAME_TARIFF_EIEAS = '/Assurance_personnelle_des_eleves_infirmiers_et_eleves_aides_soignants/affichage_formules';
export const PAGE_NAME_TARIF_VIE_PRO = '_devis_assurance_vie_pro/affichage_formule';
export const PAGE_NAME_PRESENTATION_OFFRES = 'devis_assurance_personnelle_des_infirmiers_et_aides_soignants/presentation_offres';
export const PAGE_NIVEAU2_TARIFF = 'Affichage formules';
export const PAGE_NIVEAU3_TARIF = 'Etape 11 - Presentation des offres';
export const LABEL_FORMULE_APEL = 'formule_APEL';
export const LABEL_FORMULE_EIEAS = 'formule_EIEAS';
export const LABEL_FORMULE_VIE_PRO = 'formule_VIEPRO';
export const LABEL_FORMULE_API = 'formule_API';
export const ENV_TEMPLATE_SOUSCRIPTION = 'souscription_elu';
export const ENV_TEMPLATE_SOUSCRIPTION_API = 'souscription_infirmiers_aides_soignants';
export const ENV_TEMPLATE_SOUSCRIPTION_EIEAS = 'souscription_eleves_infirmiers_soignants';
export const ENV_TEMPLATE_SIMULATION_VIE_PRO = 'simulation_vie_pro';
export const ENV_TEMPLATE_SOUSCRIPTION_VIE_PRO = 'devis_vie_pro';
export const PAGE_NIVEAU3_TARIF_API = 'Presentation des offres';
export const PAGE_NIVEAU3_TARIF_VIE_PRO = 'Etape 6 - Presentation garantie';
export const PAGE_NIVEAU3_POPIN_RENONCIATION = 'pop in droit renonciation';
export const PAGE_NAME_POPIN_RENONCIATION = 'pop_in_droit_renonciation';
export const LABEL_EVENT_CONFIRMATION_SOUSCRIPTION = 'souscription_contrat';
export const LABEL_EVENT_QUITTER = 'quitter';
export const DELAY_2S = 2000;
export const DELAY_4S = 4000;

// Path tracking
export const PATH_BASE_PRODUCT_TRACKING_CONF = '/assets/data/tracking/base-product-conf';
export const PATH_EXCEPTION_TRACKING = '/assets/data/tracking/exception-tracking';
export const PREFIX_EXCEPTION_TRACKING_FILE = 'exception-ref-question';

// Constants
export const SCREEN_NAME_SITUATION = 'situation';
export const SCREEN_NAME_SOUSCRIPTION = 'souscription';
export const APEL_SCREEN_NAME_SITUATION = 'situation';
export const APEL_SCREEN_NAME_SOUSCRIPTION = 'souscription';
export const EIEAS_SCREEN_NAME_SITUATION = 'situation';
export const EIEAS_SCREEN_NAME_SOUSCRIPTION = 'souscription';
export const API_SCREEN_NAME_SITUATION = 'situation';
export const API_SCREEN_NAME_SOUSCRIPTION = 'souscription';
export const MODE_PAYMENT = '_mode_paiement';
export const PAYMENT_OK = '_paiement_ok';
export const PAYMENT_KO = '_paiement_ko';
export const KEY_DATA_LAYER = 'dataLayer';
export const KEY_DATA_PAYMENT = 'data_payment';
export const KEY_PRODUCT_NAME = 'productName';
export const FORMULE_1 = 'Formule 1';
export const FORMULE_2 = 'Formule 2';
export const FORMULE_3 = 'Formule 3';

// autres constant
export const CONTENT_TYPE = 'Content-Type';
export const APPLICATION_JSON = 'application/json';

export const ERROR_CODE = 'errorCode';

//label message
export const PHONE_NUMBER_OPTIONAL = 'Je ne souhaite pas indiquer mon numéro de téléphone';
export const CONFIG = 'config';

export const CLIENT_AUTHENTIFIE_OK = 'O';
